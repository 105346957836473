const routesConfig = [
  // { path: "/", title: "Universal Login" },
  // { path: "/receptionist_login", title: "Receptionist Login" },
  // { path: "/receptionist_registration", title: "Receptionist Registration" },
  { path: "/superadmin-dashboard", title: "Super Admin Dashboard" },
  { path: "/super-admin-appointment", title: "Super Admin Appointment" },
  { path: "/superadmin-branch", title: "Super Admin Branch" },
  // { path: "/superadmin-add-branch", title: "Super Admin Add Branch" },
  { path: "/bill_section", title: "Bill Section" },
  // { path: "/inventory", title: "Inventory" },
  // { path: "/add-invetory", title: "Add Inventory" },
  // { path: "/edit-invetory", title: "Edit Inventory" },
  // { path: "/doctor_section", title: "Doctor Section" },
  // { path: "/register-doctor", title: "Register Doctor" },
  { path: "/super-admin-profile", title: "Super Admin Profile" },
  // { path: "/doctor-profile", title: "Doctor Profile" },
  { path: "/clinic-setting", title: "Clinic Setting" },
  { path: "/lab-setting", title: "Lab Setting" },
  // { path: "/drug-setting", title: "Drug Setting" },
  { path: "/calender-setting", title: "Calender Setting" },
  { path: "/treatment-setting", title: "Treatment Setting" },
  // { path: "/communication-setting", title: "Communication Setting" },
  { path: "/prescription-templates", title: "Prescription Templates" },
  { path: "/reports-dashboard", title: "Reports Dashboard" },
  { path: "/finance-reports", title: "Finance Reports" },
  { path: "/appointment-report", title: "Appointment Report" },
  { path: "/Billing-report", title: "Billing Report" },
  // { path: "/inventory-report", title: "Inventory Report" },
  { path: "/employee-attendance-report", title: "Employee Attendance Report" },
  { path: "/employee-details-report", title: "Employee Details Report" },
  { path: "/lab-details-report", title: "Lab Details Report" },
  { path: "/lab-test-report", title: "Lab Test Report" },
  { path: "/lab-task-report", title: "Lab Task Report" },
  { path: "/manage-staff", title: "Manage Staff" },
  { path: "/super-admin-notification", title: "Super Admin Notification" },
  // { path: "/complaint-page", title: "Complaint Page" },
  { path: "/patient-list", title: "Patient List" },
  { path: "/leave-management", title: "leave managementt" },
  { path: "*", title: "Error Page" },
];

export default routesConfig;
