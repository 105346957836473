import React from "react";
import styled from "styled-components";

const PatientsBills = () => {
  return (
    <>
      <Container>
        <h1>Patient Bill</h1>
      </Container>
    </>
  );
};

export default PatientsBills;
const Container = styled.div``;
