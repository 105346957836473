import React from "react";
import styled from "styled-components";

const HospitalPurchaseBills = () => {
  return (
    <>
      <Container>
        <h1>Hospital Purchase Bill</h1>
      </Container>
    </>
  );
};

export default HospitalPurchaseBills;
const Container = styled.div``;
